import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";
import { Property } from "../model/property.model";
import { AngularFirestore, DocumentReference } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class PropertyService {
  private _propertyID: number = 0;
  private _propertyList: Property[] = null;
  private _isPropertyListReady: boolean = false;
  private _filteredPropertyList: string[] = null;
  private property$ = new BehaviorSubject<Property[]>(this._propertyList);
  private propertyReady$ = new BehaviorSubject<boolean>(this._isPropertyListReady);
  private filterProperty$ = new BehaviorSubject<string[]>(this._filteredPropertyList);
  private _propertyRefInFirestore: DocumentReference = null;

  get propertyList() {
    return this.property$.asObservable();
  }

  get filteredPropertyList() {
    return this.filterProperty$.asObservable();
  }

  get propertyReady() {
    return this.propertyReady$.asObservable();
  }

  setFilteredPropertyList(val: string[]) {
    this._filteredPropertyList = val;
    this.filterProperty$.next(val);
  }

  constructor(private http: HttpClient, private afs: AngularFirestore) {}

  get propertyID() {
    if (this._propertyID === 0) {
      this._propertyID = +localStorage.getItem("_propertyID");
    }
    return this._propertyID;
  }

  set propertyID(val: number) {
    this._propertyID = val;
    localStorage.setItem("_propertyID", ''+val);
  }

  async setPropertyListByRef(ref, client_ids: string[]) {
    this._propertyRefInFirestore = ref;
    this._propertyList = [];
    
    client_ids.forEach((id, index) => {
      const property_ref = ref.collection('properties').doc(String(id));
      property_ref.get().then(doc => {
        if (doc.exists && (this._propertyList.findIndex(x => x.clientId === id) === -1)) {
          const upData = doc.data(); 
          // // TODO : only for demo purpose
          // upData.name = upData.name.replace("fäm Living - ", "Unicorn Groups ");
          // upData.name = upData.name.replace("fäm Living – ", "Unicorn Groups ");
          // upData.name = upData.name.replace("FAMCO ", "Unicorn Groups ");
          // console.log(upData.name);
          
          
          this._propertyList.push(upData);
          if (index+1 == client_ids.length) {
            this._propertyList = this._propertyList.sort((a, b) => (a._id > b._id) ? 1 : -1)
            this.property$.next(this._propertyList);
            this.setFilteredPropertyList(client_ids);
            this._isPropertyListReady = true;
            this.propertyReady$.next(this._isPropertyListReady);
          }
        }
      })
    });
  }

  getPropertyInfo(clientId) {
    return this._propertyList.find(x => x.clientId === clientId);
  }

  getPropertyID() {
    if (this._propertyID === 0) {
      this._propertyID = +localStorage.getItem("_propertyID");
    }
    return this._propertyID;
  }

  getPropertyByID(_id: string) {
    return this._propertyList.find(x => x._id === _id)
  }

  getProperties(body) {
    return this.http.post(`${environment.agentURL}/api/property/all_property`, body);
  }

  getPropertyDetails(body) {
    return this.http.post(`${environment.apiURL}/property/details`, body);
  }

  getPropertyStatus(body) {
    // return this.http.post(`${environment.apiURL}/property/status`, body);
    return this.http.post(`${environment.apiURL}/property/overview/trend/kpi`, body)
  }

  getAreas(body) {
    return this.http.post(`${environment.apiURL}/property/areas/list`, body);
  }

  getOwnerAreas(body) {
    return this.http.post(`${environment.apiURL}/owner/areas/list`, body);
  }

  getOwnerRooms() {
    return this.http.get(`${environment.apiURL}/owner/areas/list`);
  }

  getOwnerProperties(emailID) {
    const areaRef = this.afs.collectionGroup('areas', ref => ref.where('owner_email_list', 'array-contains', emailID));
    return areaRef.valueChanges();
    // .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //         // doc.data() is never undefined for query doc snapshots
    //         console.log(doc.id, " => ", doc.data());
    //     });
    // })
    // .catch((error) => {
    //     console.log("Error getting documents: ", error);
    // });
  }

  getAreaDetails(body) {
    return this.http.post(`${environment.apiURL}/property/areas/details`, body);
  }

  getAreaRevenueTrend(body) {
    return this.http.post(`${environment.apiURL}/property/areas/revenue`, body);
  }

  getAreaAlos(area_list: number[]) {
    const body = {
      "area_list": area_list
    }
    return this.http.post(`${environment.apiURL}/property/area/alos`, body);
  }

  getPropertyInsights(id, date) {
    return this.http.get("assets/data/property-insights.json");
  }

  getDateDrilldown(body) {
    return this.http.post(
      environment.agentURL + "/api/property/date_coverage",
      body
    );
  }

  getPropertyReviews(id) {
    const body = {
      _propertyID: id,
      from: 0,
      to: 100,
      filter: {},
      sort: {},
    };
    // return this.http.post(this.baseIP + "/api/property/reviews", body);
  }

  getPropertyCoverage(body) {
    return this.http.post(
      environment.agentURL + "/api/property/coverage",
      body
    );
  }

  getPropertyReservationDistribution(body) {
    return this.http.post(`${environment.apiURL}/reservation/status/distribution`, body);
  }

  getPropertyAddons(body) {
    return this.http.post(
      environment.agentURL + "/api/property/add_ons",
      body
    );
  }

  getGeoCoverage(body) {
    return this.http.post(`${environment.apiURL}/property/geoDistribution`, body);
  }

  getGeoRegionCoverage(clientID) {
    const body = {
      client_id: clientID
    }
    return this.http.post(`${environment.apiURL}/property/geoRegionDistribution`, body);
  }

  getRevenueDistribution(body) {
    return this.http.post(`${environment.apiURL}/transaction/coverage`, body);
  }

  getRateType(body) {
    return this.http.post(
      environment.agentURL + "/api/transaction/rate_distribution",
      body
    );
  }

  getRevenueByProperty(body) {
    return this.http.post(`${environment.apiURL}/transaction/revenue/property`,body);
  }

  // getRevenueTrend(body) {
  //   return this.http.post(
  //     environment.agentURL + "/api/transaction/property_revenue",
  //     body
  //   );
  // }

  getRevenueTrend(body) {
    return this.http.post(`${environment.apiURL}/analytics/property/trend`, body);
  }

  getSinglePropertyRevenueTrend(body) {
    return this.http.post(
      environment.agentURL + "/api/transaction/revenue_trend",
      body
    );
  }

  getVoidTransactions(body) {
    return this.http.post(`${environment.apiURL}/transaction/void`, body);
  }

  getAllOffers(body) {
    return this.http.post(environment.agentURL + "/api/property/offers", body);
  }

  getQuarterlyStats(body) {
    return this.http.post(`${environment.apiURL}/analytics/property/performance`,body);
  }

  getOccupancyVsRevenue(body) {
    // return this.http.post(`${environment.apiURL}/analytics/property/performance`,body);
    return this.http.get('assets/data/graph/occupancyVSrevenue.json');
  }

  getRevenueTrendARRRevPAR(body) {
    // return this.http.post(`${environment.apiURL}/analytics/property/trend`, body);
    return this.http.get('assets/data/propertyPerformanceTrendARRRevPAR.json');
  }

  getPropertyKPI(body) {
    return this.http.post(`${environment.apiURL}/property/kpi`,body).toPromise();
  }

  getRecommendedActions(client_list: number[]) {
    const body = {
      client_id: client_list
    }
    return this.http.post(`${environment.apiURL}/property/recommendation`,body);
  }

  getGuestTrend(client_id: string[], start: string, end: string) {
    const body = {
      client_id: client_id,
      start_date: start,
      end_date: end
    };
    return this.http.post(`${environment.apiURL}/property/trend/guests`, body);
  }

  getReservationTrend(client_id: string[], start: string, end: string) {
    const body = {
      client_id: client_id,
      start_date: start,
      end_date: end
    };
    return this.http.post(`${environment.apiURL}/property/trend/reservations`, body);
  }

  getAccommodationTrend(client_id: string[], start: string, end: string) {
    const body = {
      client_id: client_id,
      start_date: start,
      end_date: end
    };
    return this.http.post(`${environment.apiURL}/property/trend/revenue`, body);
  }

  getALOSTrend(client_id: string[], start: string, end: string) {
    const body = {
      client_id: client_id,
      start_date: start,
      end_date: end
    };
    return this.http.post(`${environment.apiURL}/property/trend/alos`, body);
  }

  getGuestMatrixTrend(client_id: string, start: string, end: string) {
    const body = {
      client_id: client_id,
      start_date: start,
      end_date: end
    };
    return this.http.post(`${environment.apiURL}/property/trend/matrix`, body);
  }

  getStayDurationTrend(client_id: string[], start: string, end: string) {
    const body = {
      client_id: client_id,
      start_date: start,
      end_date: end
    };
    return this.http.post(`${environment.apiURL}/property/trend/stayDuration`, body);
  }

  getRevenueBreakdownTrend(client_id: string[], start: string, end: string) {
    const body = {
      client_id: client_id,
      start_date: start,
      end_date: end,
    };
    return this.http.post(`${environment.apiURL}/property/trend/revenueBreakdown`, body);
  }

  getRevenueBreakdownTrendWithPage(client_id: string[], startdate: string, enddate: string, start: number, end: number) {
    const body = {
      client_id: client_id,
      start_date: startdate,
      end_date: enddate,
      start: start,
      end: end
    };
    return this.http.post(`${environment.apiURL}/property/trend/revenue/breakdown`, body);
  }

  getPropertyTrendKPI(client_id: string[], startdate: string, enddate: string) {
    const body = {
      client_id: client_id,
      start_date: startdate,
      end_date: enddate
    };
    return this.http.post(`${environment.apiURL}/property/trend/kpi/overview`, body);
  }

  getPropertyTrendKPITimeline(client_id: string[], startdate: string, enddate: string) {
    const body = {
      client_id: client_id,
      start_date: startdate,
      end_date: enddate
    };
    return this.http.post(`${environment.apiURL}/property/trend/kpi/data`, body);
  }

  getPropertyRevenueByGuestType(client_id: string[], startdate: string, enddate: string) {
    const body = {
      client_id: client_id,
      start_date: startdate,
      end_date: enddate
    };
    return this.http.post(`${environment.apiURL}/property/trend/guest/types`, body);
  }

  getPropertyRevenueByRoomType(client_id: string[], startdate: string, enddate: string) {
    const body = {
      client_id: client_id,
      start_date: startdate,
      end_date: enddate
    };
    return this.http.post(`${environment.apiURL}/property/trend/room/types`, body);
  }

  getRevenueBreakdownTrendLength(client_id: string[], startdate: string, enddate: string, start: number, end: number) {
    const body = {
      client_id: client_id,
      start_date: startdate,
      end_date: enddate
    };
    return this.http.post(`${environment.apiURL}/property/trend/revenueBreakdown/length`, body);
  }


  getBookingCategoryTrend(client_id: number[], start: string, end: string) {
    const body = {
      client_id: client_id,
      start_date: start,
      end_date: end
    };
    return this.http.post(`${environment.apiURL}/property/trend/bookingCategory`, body);
  }

  getDataCoverage(clientIDList: number[]) {
    const body = {
      client_id: clientIDList
    }
    return this.http.post(`${environment.apiURL}/property/guest/coverage`, body);
  }

  getOwnerList(clientIDList: number[]) {
    const body = {
      client_id: clientIDList
    }
    return this.http.post(`${environment.apiURL}/property/owner/list`, body);
  }

  getDateRange(duration: "Weekly" | "Monthly" | "Yearly") {
    let start_date = ''
    let end_date = ''
    const today = new Date()

    if (duration === 'Weekly') {
      let first = today.getDate() - today.getDay();
      let last = first + 6;
      start_date = new Date(today.setDate(first)).toISOString().split("T")[0];
      end_date = new Date(today.setDate(last)).toISOString().split("T")[0];

    } else if (duration === 'Monthly') {
      var m = today.getMonth() + 1;
      var y = today.getFullYear();
      var d = new Date(y, m, 0).getDate();
     
      start_date = y + '-' + (m <= 9 ? '0' + m : m) + '-01' ;
      end_date = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    } else if (duration === 'Yearly') {
      start_date = `${today.getFullYear()}-01-01`
      end_date = `${today.getFullYear()}-12-31`
    } else {
      console.error(`Invalid parameter passed in date range: ${duration}`)
    }
    return {
      start_date: start_date,
      end_date: end_date
    }
  }

  getTilesInfo(propertyList, duration) {
    
    const body = this.getDateRange(duration);
    body['property_list'] = propertyList;

    return this.http.post(`${environment.apiURL}/property/overview`, body);
  }

  getTrend(propertyList, trend: string, duration) {
    let temp = this.getDateRange(duration)
    let body = {
      'startDate': temp.start_date,
      'endDate': temp.end_date,
      'propertyList': propertyList,
      'duration': duration,
      'trend': trend
    }
    return this.http.post(`${environment.apiURL}/property/overview/trend`, body);
  }

  

}
